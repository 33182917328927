import { ICandidate } from '@EZenkof/common-recruiter-library';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  filterCandidatesReducer,
  filterCandidatesReducerFulfilled,
  filterCandidatesReducerPending,
  filterCandidatesReducerRejected,
} from './reducers/filter-candidates.reducer';
import {
  updateCandidateViewCardStatusReducer,
  updateCandidateViewCardStatusReducerFulfilled,
  updateCandidateViewCardStatusReducerPending,
  updateCandidateViewCardStatusReducerRejected,
} from './reducers/update-candidate-view-card-status.reducer';
import {
  updateCandidateToVacancyReducer, updateCandidateToVacancyReducerFulfilled,
  updateCandidateToVacancyReducerPending, updateCandidateToVacancyReducerRejected,
} from './reducers/update-candidate-to-vacancy.reducer';
import {
  filterCandidatesCountReducer,
  filterCandidatesCountReducerFulfilled,
  filterCandidatesCountReducerPending,
  filterCandidatesCountReducerRejected,
} from './reducers/filter-candidates-count.reducer';

export interface ICandidatesSlice {
  candidates: ICandidate[];
  count: number;
  isCandidatesLoading: boolean;
  isCountLoading: boolean;
  isSearched: boolean;
  page: number;
}

const initialState: ICandidatesSlice = {
  candidates: [],
  count: 0,
  isCandidatesLoading: false,
  isCountLoading: false,
  isSearched: true,
  page: 0,
};

const CandidatesSlice = createSlice({
  name: 'candidatesSlice',
  initialState: initialState,
  reducers: {
    resetCandidates: (state) => {
      state.candidates = [];
      state.count = 0;
      state.page = 0;
    },
    setPage: (state, action: PayloadAction<number>) => {
      state.page = action.payload;
    },
    setIsSearched(state, action: PayloadAction<boolean>) {
      state.isSearched = action.payload;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(updateCandidateViewCardStatusReducer.pending, updateCandidateViewCardStatusReducerPending)
      .addCase(updateCandidateViewCardStatusReducer.fulfilled,updateCandidateViewCardStatusReducerFulfilled)
      .addCase(updateCandidateViewCardStatusReducer.rejected, updateCandidateViewCardStatusReducerRejected)

      .addCase(filterCandidatesReducer.pending, filterCandidatesReducerPending)
      .addCase(filterCandidatesReducer.fulfilled, filterCandidatesReducerFulfilled)
      .addCase(filterCandidatesReducer.rejected, filterCandidatesReducerRejected)

      .addCase(filterCandidatesCountReducer.pending, filterCandidatesCountReducerPending)
      .addCase(filterCandidatesCountReducer.fulfilled, filterCandidatesCountReducerFulfilled)
      .addCase(filterCandidatesCountReducer.rejected, filterCandidatesCountReducerRejected)
    
      .addCase(updateCandidateToVacancyReducer.pending, updateCandidateToVacancyReducerPending)
      .addCase(updateCandidateToVacancyReducer.fulfilled, updateCandidateToVacancyReducerFulfilled)
      .addCase(updateCandidateToVacancyReducer.rejected, updateCandidateToVacancyReducerRejected);
  },
});

export const {
  resetCandidates,
  setPage,
  setIsSearched,
} = CandidatesSlice.actions;

export default CandidatesSlice;
