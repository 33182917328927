import { UpdateRecruitingVacancyById, IUpdateRecruitingVacancyDto } from '@EZenkof/common-recruiter-library';
import { PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';

import { IVacanciesSlice } from '../vacancies.slice';

interface IUpdate {
  vacancy: IUpdateRecruitingVacancyDto;
  id: number;
}

export const updateVacancyReducer = createAsyncThunk<IUpdate, IUpdate>(
  'vacancies/updateVacancy',
  async ({ id, vacancy }, { rejectWithValue }) => {
    try {
      await UpdateRecruitingVacancyById(id, vacancy);

      return { vacancy, id };
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  },
);

export const updateVacancyReducerPending = (state: IVacanciesSlice) => {
  state.isMinorReducerLoading = true;
};

export const updateVacancyReducerFulfilled = (state: IVacanciesSlice, action: PayloadAction<IUpdate>) => {
  state.vacancies = state.vacancies.map((v) => {
    if (v.id === action.payload.id) return { ...v, ...action.payload.vacancy };
    return v;
  });

  state.isMinorReducerLoading = false;
};

export const updateVacancyReducerRejected = (state: IVacanciesSlice) => {
  state.isMinorReducerLoading = false;
};
