import { createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import {
  ECandidateViewedCardStatus,
  ECandidateViewedStatus,
  PreMarkRecruitingMarkVacancyToCandidates,
} from '@EZenkof/common-recruiter-library';

import { ICandidatesSlice } from '../candidates.slice';

interface IUpdateCandidates {
  candidateIds: number[];
  recruitingVacancyId: number;
  viewedCandidateCardStatus: ECandidateViewedCardStatus;
}

interface IUpdateCandidatesResponse {
  candidateIds: number[];
  recruitingVacancyId: number;
  viewedCandidateCardStatus: ECandidateViewedCardStatus;
}

export const updateCandidateViewCardStatusReducer = createAsyncThunk<IUpdateCandidatesResponse, IUpdateCandidates>(
  'candidatesSlice/updateCandidateStatus',
  async ({ candidateIds, recruitingVacancyId, viewedCandidateCardStatus }, { rejectWithValue }) => {
    try {
      await PreMarkRecruitingMarkVacancyToCandidates({
        recruitingVacancyId,
        candidateIds,
        field: 'viewedCandidateCardStatus',
      });

      return {
        candidateIds,
        recruitingVacancyId,
        viewedCandidateCardStatus,
      };
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  },
);

export const updateCandidateViewCardStatusReducerPending = (state: ICandidatesSlice) => {};

export const updateCandidateViewCardStatusReducerFulfilled = (
  state: ICandidatesSlice,
  action: PayloadAction<IUpdateCandidatesResponse>,
) => {
  const { candidateIds, viewedCandidateCardStatus } = action.payload;

  state.candidates = state.candidates.map((candidate) => 
    candidateIds.includes(candidate.id)
      ? {
        ...candidate, 
        viewedCandidateCardStatus,
        viewedStatus: 
          candidate.viewedStatus === ECandidateViewedStatus.new || 
          !candidate.viewedStatus 
            ? ECandidateViewedStatus.preViewed 
            : candidate.viewedStatus,
      }
      : candidate,
  );
};

export const updateCandidateViewCardStatusReducerRejected = (state: ICandidatesSlice) => {};
