import React from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { updateGuideReducer } from 'store/slices/guide/guide.slice';

import { useAppDispatch } from 'store/interface/store.interface';

import TipModal from './tip.modal';
import styles from './styles.module.scss';

interface Props {
  active: boolean;
  setActive: () => void;
}

const OnStartTipModal = ({ active, setActive }: Props) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const handleOpenGuide = () => dispatch(updateGuideReducer({
    step: 0,
    run: true,
  }));

  const handleClose = () => {
    setActive();

    handleOpenGuide();
  };

  return (
    <TipModal active={active} setActive={handleClose}>
      <div className={styles.startTip}>
        <p>
          <Trans i18nKey={t('startModal.header')} components={{ b: <b /> }} />
        </p>
        <p>{t('startModal.tip')}</p>
      </div>
    </TipModal>
  );
};

export default OnStartTipModal;
